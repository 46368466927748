<template>
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-9 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <div class="me-7 mb-4">
          <div class=" symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ">
						<img :src=" user.avatar_url ? user.avatar_url : getUiAvatarSrc(user.name +  ' ' + user.surname,user.active)" :alt="user.name + ' ' + user.surname" />
						<a
							data-bs-toggle="tooltip" title="Profil Resmini Değiştir" class="position-absolute translate-middle bottom-0 start-100 mb-6 fs-2 fas fa-plus-square h-20px w-20px"></a>
					</div>
        </div>
        <div class="flex-grow-1">
          
          <div class=" d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <a href="javascript:;" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{ user.name }}
                  {{ user.surname }}</a>
                <span v-if="(user.user_type = 1)" v-html="user.active == 1 ? 'Aktif Hesap' : 'Pasif Hesap'"
                  href="javascript:;" :class="
                    user.active == 1 ? 'badge-light-success' : 'badge-light-danger'
                  " class="fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal"></span>
                <span v-if="user.active" class="fw-bolder ms-2 fs-8 py-1 px-3 badge-light-info" 
                data-bs-toggle="modal">Aktiflik Bitiş Tarihi : {{userActiveTime(user.sureli_aktiflik_son_tarih)}}
                </span>
                <a v-if="(user.user_type = 0)" class=" btn btn-sm fw-bolder btn-light-warning ms-2 fs-8 py-1 px-3
                  " data-bs-toggle="modal">Müşteri Hesabı</a>
              </div>
              <div class="d-flex flex-wrap fs-6 mb-4">
                <span class="d-flex align-items-center text-gray-500 me-8 mb-2">{{ user.packetName }}</span>
                <span class="d-flex align-items-center text-gray-500 me-8 mb-2">{{ user.careerName }}</span>
                <span data-bs-toggle="tooltip" title="Toplam Üye: 45 
                Pasif Üye: 6" class="d-flex align-items-center text-danger me-8 mb-2">Üye Sayısı : 45/6</span>
                <span class="d-flex align-items-center text-gray-500 mb-2 me-8">
                  {{ user.sponsor_id }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-grow-1 pe-4">
            <div class="d-flex flex-wrap flex-stack justify-content-start">

              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-3 mb-2">
                <div class="fw-bold fs-6 text-gray-400">Sol Kol</div>
                <div class="fs-2 fw-bolder">
                  {{ NumberFormat ( ( left ).toFixed ( 2 ) )}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-2 mb-2">
                <div class="fw-bold fs-6 text-gray-400">Sağ Kol</div>
                <div class="fs-2 fw-bolder">
                  {{ NumberFormat ( ( right ).toFixed ( 2 ) )}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-2 mb-2">
                <div class="fw-bold fs-6 text-gray-400">Aylık Kazanç</div>
                <div class="fs-2 fw-bolder">
                  {{ NumberFormat ( ( monthEarnings ).toFixed ( 2 ) )}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-3 mb-2" v-if="referenceDetail">
                <div class="fw-bold fs-6 text-gray-400">{{ referenceDetail.name }}</div>
                <div class="fs-2 fw-bolder">
                  {{ referenceDetail.type ==='earnings' ? NumberFormat ( ( referenceSum ).toFixed ( 2 ) ): NumberFormat ( ( referenceSum/(networkSettings.point_multiplier ? networkSettings.point_multiplier :1)  ).toFixed ( 2 ))}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-3 mb-2" v-if="referenceKademeDetail">
                <div class="fw-bold fs-6 text-gray-400">{{ referenceKademeDetail.name }}</div>
                <div class="fs-2 fw-bolder">
                  {{ referenceKademeDetail.type ==='earnings' ? NumberFormat ( ( referenceKademeSum ).toFixed ( 2 ) ): NumberFormat ( ( referenceKademeSum/(networkSettings.point_multiplier ? networkSettings.point_multiplier :1)  ).toFixed ( 2 ))}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-3 mb-2" v-if="sponsor50Detail">
                <div class="fw-bold fs-6 text-gray-400">{{ sponsor50Detail.name }}</div>
                <div class="fs-2 fw-bolder">
                  {{ sponsor50Detail.type ==='earnings' ? NumberFormat ( ( sponsor50Sum ).toFixed ( 2 ) ): NumberFormat ( ( sponsor50Sum/(networkSettings.point_multiplier ? networkSettings.point_multiplier :1)  ).toFixed ( 2 ))}} <small>{{ currency }}</small>
                </div>
              </div>
              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-3 mb-2" v-if="sponsorDetail">
                <div class="fw-bold fs-6 text-gray-400">{{ sponsorDetail.name }}</div>
                <div class="fs-2 fw-bolder">
                  {{ sponsorDetail.type ==='earnings' ? NumberFormat ( ( sponsorSum ).toFixed ( 2 ) ): NumberFormat ( ( sponsorSum/(networkSettings.point_multiplier ? networkSettings.point_multiplier :1)  ).toFixed ( 2 ))}} <small>{{ currency }}</small>
                </div>
              </div>

              <div class="d-flex-row border border-gray-300 border-dashed rounded min-w-80px py-3 px-3 me-2 mb-2">
                <div class="fw-bold fs-6 text-gray-400">Toplam Kazanç</div>
                <div class="fs-2 fw-bolder">
                  {{ NumberFormat ( ( totalEarnings ).toFixed ( 2 ) )}} <small>{{ currency }}</small>
                </div>
              </div>





            </div>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-default">
        <div class="d-flex flex-wrap">
          <ul class=" nav nav-bar nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap ">
            <li class="nav-item ms-3 me-2 py-2">
              <router-link :to="'/user_edit_profile_page/'+ user.sponsor_id" class="nav-link text-active-primary me-6 show"
                active-class="active">
                {{ l("admin:languages:languages458") }}
              </router-link>
            </li>
            <li class="nav-item ms-3 me-2 py-2">
              <router-link :to="'/payment_methods_page/'+ user.sponsor_id" class="nav-link text-active-primary me-6"
                active-class="active">
                Ödeme Methodları <span style="margin-left:5px" class="fs-10 badge badge-danger">Beta</span>
              </router-link>
            </li>
             <li class="nav-item ms-3 me-2 py-2">
              <router-link :to="'/faturalar_page/'+ user.sponsor_id" class="nav-link text-active-primary me-6"
                active-class="active">
                Faturalar <span style="margin-left:5px" class="fs-10 badge badge-danger">Beta</span>
              </router-link>
            </li>

            <li class="nav-item ms-3 me-2 py-2">
              <router-link :to="'/siparisler_detail/'+ user.sponsor_id" class="nav-link text-active-primary me-6"
                active-class="active">
                Siparişler <span style="margin-left:5px" class="fs-10 badge badge-danger">Beta</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
 <router-view></router-view>
</template>

<script>
  import "jquery/dist/jquery.min.js";
  import {
    useRoute
  } from "vue-router";
  import {
    api_url
  } from "@/ayar";
  import post from "@/core/functions/post";
  import {
    getUiAvatarSrc
  } from "@/ayar";
  import {
    setCurrentPageTitle
  } from "@/core/helpers/breadcrumb";
  import {
    l
  } from "@/core/helpers/lang";
  import get from "@/core/functions/get";
  import NumberFormat from "@/core/functions/NumberFormat";
  import moment from 'moment';

  export default {
    name: "UserProfilePage",
    data: () => {
      return {
        cities: [],
        district: [],
        isName: true,
        isTc: true,
        isIban: true,
        isAddress: true,
        isCity: true,
        user: [],
        left: 0,
        right:0,
        monthEarnings:0,
        networkSettings :[],
        referenceDetail:[],
        referenceKademeDetail:[],
        sponsor50Detail:[],
        sponsorDetail:[],
        referenceKademeSum:[],
        referenceSum:[],
        sponsorSum:[],
        sponsor50Sum:[],
        totalEarnings:0,
        currency:null,
      };
    },
    methods: {
      changeCity(city) {
        post(api_url + `district`, {
          id: city,
        }).then((res) => {
          this.district = res.data;
        });
      },
      userActiveTime(date){
        if (date) {
          // Data change format
          moment.locale('tr');
          return moment(date).calendar();
          // İki tarih arasında ki gün sayısı
          // moment.locale('tr');
          // var m = moment(date);  // or whatever start date you have
          // var today = moment().startOf('day');

          // var days = Math.round(moment.duration(m - today).asDays());
          // return days;
        }
      }
    },
    setup() {
      const route = useRoute();

      return {
        route,
        getUiAvatarSrc,
        l,
        NumberFormat
      };
    },
    mounted() {
      const route = useRoute();
      post(api_url + `user/` + route.params.id, {}).then((res) => {
        this.user = res.data.data;
        this.left = res.data.left;
        this.right = res.data.right;
        this.monthEarnings = res.data.monthEarnings;
        this.networkSettings = res.data.networkSettings;
        this.referenceDetail = res.data.referenceDetail;
        this.referenceKademeDetail = res.data.referenceKademeDetail;
        this.sponsor50Detail = res.data.sponsor50Detail;
        this.sponsorDetail = res.data.sponsorDetail;
        this.referenceKademeSum = res.data.referenceKademeSum;
        this.sponsorSum = res.data.sponsorSum;
        this.sponsor50Sum = res.data.sponsor50Sum;
        this.totalEarnings = res.data.totalEarnings;
        this.referenceSum = res.data.referenceSum;
        this.currency = res.data.currency;
      });
      get(api_url + `cities`, {}).then((res) => {
        this.cities = res.data;
      });
       setCurrentPageTitle("Kullanıcı Profili");
    },
  };
</script>

<style lang="css" scoped>
  @media (max-width: 768px) {
    .nav-line-tabs {
      display: flex;
      flex-wrap: wrap !important;
    }

    .nav-line-tabs li {
      width: 100%;
    }

  }
</style>